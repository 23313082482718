import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useState } from "react";
import { DeleteTaskModal } from "../DeleteTaskModal";
import { AssignToNegotiatorModal } from "../AssignToNegotiatorModal";
import { ReAssignTask } from "../Reassign";

export const OrderTasksActions = ({
  taskId,
  refetch,
  status,
  setSelectedTasks,
  assignedStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const stopPropagating = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div onClick={stopPropagating}>
      <Button
        id="change-status-button"
        aria-controls={open ? "change-status-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </Button>
      <Menu
        id="change-status-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {status.key === 4 && (
          <MenuItem sx={{ px: 0 }}>
            <AssignToNegotiatorModal
              setSelectedTasks={setSelectedTasks}
              taskId={[taskId]}
              refetch={refetch}
            />
          </MenuItem>
        )}

        <ReAssignTask
          taskId={taskId}
          refetch={refetch}
          assignedStatus={assignedStatus}
        />
        <MenuItem sx={{ px: 0 }}>
          <DeleteTaskModal taskId={taskId} refetch={refetch} />
        </MenuItem>
      </Menu>
    </div>
  );
};
