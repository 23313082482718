import { Box, useTheme } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import Select, { components } from "react-select";

const CustomValueContainer = ({ children, ...props }) => {
  const [values, input] = children;
  const maxToShow = 1;

  let displayValues = values;
  let extraCount = 0;

  if (Array.isArray(values) && values.length > maxToShow) {
    displayValues = values.slice(0, maxToShow);
    extraCount = values.length - maxToShow;
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <components.ValueContainer {...props}>
      {displayValues}
      {extraCount > 0 && (
        <Box sx={{ background: "#e6e6e6", m: 0.2, py: 0.1, px: 0.4 }}>
          {" "}
          +{extraCount}{" "}
        </Box>
      )}
      {input}
    </components.ValueContainer>
  );
};

export const FilterSelect = ({ options, name, isMulti, ...rest }: any) => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const { shape, palette } = useTheme();

  return (
    <Select
      // formatOptionLabel={}
      components={{ ValueContainer: CustomValueContainer }}
      isMulti={isMulti}
      isClearable={false}
      // controlShouldRenderValue={isMulti ? false : true}
      hideSelectedOptions={false}
      focusDefaultOption={false}
      options={options}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: shape.borderRadius,
          outline: "red",
          boxShadow: state.isFocused ? "0px 0px 1px 1px #000" : "none",
          borderColor: "#ccc",
          "&:focus": {
            borderColor: palette.primary.main,
          },
          "&:hover": {
            borderColor: palette.primary.main,
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected && palette.primary.light,
        }),
      }}
      value={searchParams.getAll(name).map((URLValue: string) => {
        return options?.find((option: any) => option.value === URLValue);
      })}
      defaultValue={searchParams.getAll(name).map((URLValue: string) => {
        return options?.find((option: any) => option.value === URLValue);
      })}
      onChange={(selected: any) => {
        setSearchParams((params) => {
          params.delete(name);
          params.delete("page");
          if (isMulti) {
            selected.forEach((option: any) => {
              params.append(name, option.value);
            });
          } else {
            selected?.value !== undefined && params.set(name, selected?.value);
          }
          return params;
        });
      }}
      {...rest}
    />
  );
};
