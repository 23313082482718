import { gql } from "@apollo/client";

export const GET_BENEFICIARIES_FROM_TASKS = gql`
  query GetTasks(
    $limit: BoundedInt!
    $role: UserRoleChoices!
    $offset: BoundedInt!
    $filters: [DjangoFilterInput]!
    $orderBy: String!
  ) {
    tasks(
      role: $role
      filters: $filters
      pageInfo: { limit: $limit, offset: $offset, orderBy: $orderBy }
    ) {
      data {
        id
        status
        beneficiary {
          code
          externalId
        }
        customerService {
          id
          firstName
          lastName
        }
        negotiator {
          id
          firstName
          lastName
        }
        formData
        assignedStatus
      }
      count
    }
  }
`;
