import { gql } from "@apollo/client";

export const CREATE_EXTERNAL_OFFER = gql`
  mutation CreateExternalOffer($offerInput: ExternalFavoriteOfferInputType!) {
    createExternalOfferMutation(offerInput: $offerInput) {
      favoriteOffer {
        id
      }
    }
  }
`;

export const DELETE_OFFER_FROM_FAVOURITES = gql`
  mutation DeleteOffer($dealInput: DeleteOfferInputType!) {
    deleteOfferMutation(dealInput: $dealInput) {
      message
    }
  }
`;
