import { gql } from "@apollo/client";

export const REJECT_DEAL = gql`
  mutation RejectDeal($dealInput: RejectDealInputType!) {
    rejectDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;

export const FINISH_DEAL = gql`
  mutation finishDeal($dealInput: FinishDealInputType!) {
    finishDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;

export const CREATE_DEAL = gql`
  mutation createDeal($dealInput: CreateDealInputType!) {
    createDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal($dealInput: UpdateDealInputType!) {
    updateDealMutation(dealInput: $dealInput) {
      deal {
        id
      }
    }
  }
`;
