import { EmptyData } from "@/components/EmptyData";
import { Loading } from "@/components/Loading";
import { GET_ALL_RECOMMENDATIONS } from "@/shared/graphql/queries/recommendations";
import { useQuery } from "@apollo/client";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

export const RegionRecommendation = () => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const { data, loading, error } = useQuery(GET_ALL_RECOMMENDATIONS, {
    variables: { taskId, level: "CITY" },
  });

  if (loading) return <Loading />;
  if (error) return <></>;
  if (
    data?.recommendations?.data?.recommend_districts_by_preferences.length === 0
  )
    return <EmptyData />;

  return (
    <Card variant="outlined">
      <TableContainer>
        <Table
          sx={{ minWidth: 600, tableLayout: "fixed" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("ID")}</TableCell>
              <TableCell>{t("City")}</TableCell>
              <TableCell>{t("District")}</TableCell>
              <TableCell>{t("District class")}</TableCell>
              <TableCell>{t("Zone")}</TableCell>
              <TableCell>{t("Sub zone")}</TableCell>
              <TableCell>{t("Similatiy percentage")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.recommendations?.data?.recommend_districts_by_preferences?.map(
              (recommendation) => (
                <TableRow>
                  <TableCell>{recommendation.id}</TableCell>
                  <TableCell>{recommendation.city_name}</TableCell>
                  <TableCell>{recommendation.district_name}</TableCell>
                  <TableCell>{recommendation.district_class}</TableCell>
                  <TableCell>{recommendation.main_zone}</TableCell>
                  <TableCell>{recommendation.sub_zone}</TableCell>
                  <TableCell>{recommendation.sutiability}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
