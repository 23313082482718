import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExternalOffer } from "./ExternalOffer";
import { FavouriteOffersActions } from "./FavouriteOffersActions";
import { useQuery } from "@apollo/client";
import { GET_ALL_FAVOURITE_OFFERS } from "@/shared/graphql";
import { useParams } from "react-router";
import { Loading } from "@/components/Loading";

export const FavouriteOffers = ({ setSelectedStep, refetchTask }) => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const {
    data,
    refetch: refetchFavOffers,
    loading,
    error,
  } = useQuery(GET_ALL_FAVOURITE_OFFERS, {
    variables: { taskId },
  });

  if (loading) return <Loading />;
  if (error) return <></>;

  return (
    <>
      {data?.favoriteOffers?.data.length > 0 && (
        <Box>
          <Typography variant="h6" fontWeight="bold" sx={{ mb: 1 }}>
            {t("Favourite offers")}
          </Typography>
          <Card sx={{ mb: 2, maxWidth: 700 }} variant="outlined">
            {/* <TableContainer>
          <Table sx={{ minWidth: 600 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t("Property type")}</TableCell>
                <TableCell>{t("Address")}</TableCell>
                <TableCell>{t("Area")}</TableCell>
                <TableCell>{t("Price")}</TableCell>
                <TableCell>{t("Rooms")}</TableCell>
                <TableCell>{t("Bathroom")}</TableCell>
                <TableCell>{t("Offer Status")}</TableCell>
                <TableCell>{t("Number of negotiators")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell>شقة</TableCell>
              <TableCell>الرمال شرق الرياض</TableCell>
              <TableCell>250 قدم متر مربع</TableCell>
              <TableCell>100000 ر.س</TableCell>
              <TableCell>2</TableCell>
              <TableCell>1</TableCell>
              <TableCell>غير محجوز</TableCell>
              <TableCell>3</TableCell>
              <TableCell>
                <FavouriteOffersActions />
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer> */}
            <TableContainer>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t("licenseNumber")}</TableCell>
                    <TableCell>{t("url")}</TableCell>
                    <TableCell>{t("Status")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.favoriteOffers?.data?.map((offer) => {
                    return (
                      <TableRow>
                        <TableCell>{offer.licenseNumber}</TableCell>
                        <TableCell>
                          <Box
                            sx={{ color: "#328AF1" }}
                            component="a"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={offer.data?.url}
                          >
                            {offer.data?.url}
                          </Box>
                        </TableCell>
                        <TableCell>{t(offer?.status?.display)}</TableCell>
                        <TableCell>
                          <FavouriteOffersActions
                            refetchTask={refetchTask}
                            refetchFavOffers={refetchFavOffers}
                            favoriteOfferId={offer.id}
                            setSelectedStep={setSelectedStep}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Box>
      )}

      <ExternalOffer
        refetchTask={refetchTask}
        setSelectedStep={setSelectedStep}
      />
    </>
  );
};
