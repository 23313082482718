import { FC } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { useSorting } from "@/shared/hooks";
import { numberFormatter } from "@/shared/utils";
import { EmptyData } from "@/components/EmptyData/EmptyData";

export const BeneficiariesListNegotiator: FC<any> = ({ tasks }) => {
  const { t } = useTranslation();
  const { orderBy, sortingValue } = useSorting();

  if (tasks?.length === 0) return <EmptyData />;

  return (
    <>
      <TableContainer>
        <Table
          sx={{ minWidth: 1600, position: "relative" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>{t("ID_CODE")}</TableCell>
              <TableCell>{t("External ID")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("personalData__ageHijri")}
                >
                  {t("Hijri Age")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("personalData__ageHijri") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Job")}</TableCell>
              <TableCell>{t("Region")}</TableCell>
              <TableCell>{t("City")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() =>
                    orderBy("familyData__familyMembersCount")
                  }
                >
                  {t("Number of family members")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("familyData__familyMembersCount") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Product")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() => orderBy("financialData__salary")}
                >
                  {t("Salary")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("financialData__salary") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Salary Bank")}</TableCell>
              <TableCell>
                <Button
                  sx={{
                    color: "inherit",
                    fontWeight: "inherit",
                    textAlign: "inherit",
                  }}
                  onClick={() =>
                    orderBy("financialData__fundingAmount")
                  }
                >
                  {t("Average Fund")}
                  {sortingValue?.at(0) === "-" &&
                  sortingValue?.includes("financialData__fundingAmount") ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("Order Entity")}</TableCell>
              <TableCell>{t("Assigned to")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task: any) => {
              return (
                <TableRow
                  key={task.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{task?.beneficiary?.code}</TableCell>
                  <TableCell>{task?.beneficiary?.externalId}</TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.personalData?.name}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.personalData?.ageHijri}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.professionalData?.job}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.location?.region}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.location?.city}
                  </TableCell>
                  <TableCell>
                    {
                      task?.formData?.negotiatorData?.familyData
                        ?.familyMembersCount
                    }
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.supportPackages?.product}
                  </TableCell>
                  <TableCell>
                    {numberFormatter(
                      task?.formData?.negotiatorData?.financialData?.salary
                    )}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.financialData?.salaryBank}
                  </TableCell>
                  <TableCell>
                    {numberFormatter(
                      task?.formData?.negotiatorData?.financialData
                        ?.fundingAmount
                    )}
                  </TableCell>
                  <TableCell>
                    {task?.formData?.negotiatorData?.destination}
                  </TableCell>
                  <TableCell>
                    {task?.negotiator?.firstName}{" "}
                    {task?.negotiator?.lastName}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
