import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  SelectWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Button } from "@mui/material";
import {
  insertCitiesInFormSchema,
  insertZonesInFormSchema,
} from "../TaskFormUtils";
import {
  fetchAllCitiesByRegionId,
  fetchAllRegions,
  fetchAllZonesByRegionId,
} from "@/shared/graphql/services/layers";
import {
  ASSIGN_TO_PROJECT_MANAGER,
  UPDATE_TASK,
} from "@/shared/graphql/mutations";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { isCustomerService } from "@/shared/utils/mappers";
// import schema2 from "@/schemas/2-client-preferences.json";
// import uiSchema2 from "@/schemas/ui-schemas/2-client-preferences.json";

const intitialFormData = {
  enthusiasmLevel: {},
  realEstatePreferences: {},
  locationPreferences: {},
  financialPreferences: {},
  negotiatorContactTime: {},
};

export const TaskFormClientPreferences = ({ formKey }) => {
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<any>(intitialFormData);
  const [formSchema, setFormSchema] = useState();
  const [uiSchema, setUiSchema] = useState({});
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const canSubmit = userInfo?.isSuperuser || isCustomerService(userInfo);
  const [regions, setRegions] = useState<{ [x: string]: number }>({});
  const [cities, setCities] = useState<{ [x: string]: number }>({});

  const { data: taskRes, loading: taskLoading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      if (res?.tasks?.data?.at(0)?.formData?.drafts?.clientPreferences) {
        setFormData((prev) => ({
          ...prev,
          ...res?.tasks?.data?.at(0)?.formData?.drafts?.clientPreferences,
        }));
      } else {
        const clientPreferencesFormData =
          res?.tasks?.data?.at(0)?.formData?.clientPreferences;
        setFormData((prev) => ({ ...prev, ...clientPreferencesFormData }));
      }
    },
  });

  const { loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    skip: !taskRes,
    onCompleted: async (res) => {
      const requestedFormData = JSON.parse(
        JSON.stringify(
          taskRes?.tasks?.data?.at(0)?.formData?.drafts?.clientPreferences ||
            taskRes?.tasks?.data?.at(0)?.formData?.clientPreferences ||
            intitialFormData
        )
      );
      const schema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema)
      );
      const fetchedFormSchema = schema.form;
      const fetchedUiSchema = schema?.UISchema;

      setUiSchema(fetchedUiSchema);

      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );
      // set region id
      requestedFormData.locationPreferences.regionId =
        regionsMap[requestedFormData.locationPreferences.preferredRegion];
      setRegions(regionsMap);

      if (
        fetchedFormSchema?.definitions?.locationPreferences?.properties
          ?.preferredRegion
      ) {
        fetchedFormSchema.definitions.locationPreferences.properties.preferredRegion.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }

      // checking if the region default value exists
      const prefilledRegion =
        requestedFormData?.locationPreferences?.preferredRegion ||
        requestedFormData?.preferredRegion;
      if (regionsMap[prefilledRegion]) {
        // fetchCities by region id
        const citiesRes = await fetchAllCitiesByRegionId(
          regionsMap[prefilledRegion]?.toString()
        );

        const allCities = citiesRes?.data?.cities?.data;
        const citiesMap = allCities.reduce((hashmap, item) => {
          hashmap[item?.sourceProperties.city_name] =
            item?.sourceProperties?.id;
          return hashmap;
        }, {});
        // set city id
        requestedFormData.locationPreferences.cityId =
          citiesMap[requestedFormData.locationPreferences.preferredCity];
        setCities(citiesMap);
        if (
          fetchedFormSchema?.definitions?.locationPreferences?.properties
            ?.preferredCity
        ) {
          fetchedFormSchema.definitions.locationPreferences.properties.preferredCity.enum =
            allCities?.map((city) => city.sourceProperties.city_name);
        }
      } else {
        // set formData region null
        // set formData city null
        delete requestedFormData.locationPreferences.preferredRegion;
        delete requestedFormData.locationPreferences.preferredCity;
      }

      setFormData((prev) => ({ ...prev, ...requestedFormData }));
      setFormSchema(fetchedFormSchema);
    },
  });

  const [assignToProjectManager, { loading: assignLoading }] = useMutation(
    ASSIGN_TO_PROJECT_MANAGER,
    {
      onCompleted: () => {
        navigate("../tasks");
        toast.success(t("Data has been saved successfully"));
      },
      onError: (err) => {
        graphqlErrorHandler(err);
      },
    }
  );

  const [updateTask, { loading: mutationLoading }] = useMutation(UPDATE_TASK);
  const onFormChange = async (form: any, fieldId) => {
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));

    if (fieldId === "root_locationPreferences_preferredRegion") {
      if (clonedFormData?.locationPreferences?.preferredCity) {
        delete clonedFormData.locationPreferences.preferredCity;
        delete clonedFormData.locationPreferences.cityId;
      }
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
        delete clonedFormData.locationPreferences.mainDivisionId;
      }
      // set region id
      clonedFormData.locationPreferences.regionId =
        regions[form?.formData?.locationPreferences?.preferredRegion];
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[
          form?.formData?.locationPreferences?.preferredRegion
        ]?.toString()
      );
      const allCities = citiesRes?.data?.cities?.data;
      const citiesMap = allCities.reduce((hashmap, item) => {
        hashmap[item?.sourceProperties.city_name] = item?.sourceProperties?.id;
        return hashmap;
      }, {});
      setCities(citiesMap);
      const newSchema = insertCitiesInFormSchema(formSchema, allCities);
      setFormSchema(newSchema);
    }
    if (fieldId === "root_locationPreferences_preferredCity") {
      if (clonedFormData?.locationPreferences?.mainDivision) {
        delete clonedFormData.locationPreferences.mainDivision;
        delete clonedFormData.locationPreferences.mainDivisionId;
      }
      // set city id
      clonedFormData.locationPreferences.cityId =
        cities[form?.formData?.locationPreferences?.preferredCity];
      const zonesRes = await fetchAllZonesByRegionId(
        cities[form?.formData?.locationPreferences?.preferredCity]?.toString()
      );
      const allZones = zonesRes?.data?.zones?.data?.map((zone) => ({
        id: zone.sourceProperties.id,
        label: zone.sourceProperties?.zone_name,
      }));

      const newSchema = insertZonesInFormSchema(formSchema, allZones);

      setFormSchema(newSchema);
    }

    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };
  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const saveAsDraft = () => {
    const submittedData = { clientPreferences: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        toast.success(t("data has been drafted"));
        navigate("../tasks");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    const isString = (value) =>
      typeof value === "string" || value instanceof String;
    const clonedFormData = JSON.parse(JSON.stringify(form.formData));
    const mainDivision = clonedFormData.locationPreferences.mainDivision;

    clonedFormData.locationPreferences.mainDivision = mainDivision?.map(
      (el) => {
        if (isString(el)) {
          return {
            id: null,
            label: el,
          };
        }
        return el;
      }
    );

    // return;
    const submittedData = { clientPreferences: clonedFormData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        assignToProjectManager({ variables: { taskInput: { taskId } } }).catch(
          (err) => {
            graphqlErrorHandler(err);
          }
        );
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };
  if (schemaLoading || taskLoading) return <Loading />;
  return (
    <>
      <Form
        formContext={{ onFileChange }}
        schema={formSchema || {}}
        uiSchema={uiSchema || {}}
        validator={validator}
        onChange={onFormChange}
        formData={formData}
        noHtml5Validate
        showErrorList={false}
        transformErrors={(errors) => {
          const modfiedErrors = errors?.map((err) => {
            if (
              err.name === "required" ||
              err.name === "minItems" ||
              err.name === "type"
            ) {
              return { ...err, message: "حقل مطلوب" };
            }
            if (err.name === "enum") {
              return {
                ...err,
                message: "يرجى الإختيار من القيم الموجودة",
              };
            }
            if (err.name === "if") {
              return {
                ...err,
                message: "",
              };
            }
            return err;
          });
          return modfiedErrors;
        }}
        widgets={{
          FileWidget,
          YesNoWidget,
          TextWidget,
          SelectWidget,
          DateWidget: DatePickerWidget,
        }}
        templates={{ ObjectFieldTemplate }}
        onSubmit={onSubmit}
      >
        <Button
          onClick={saveAsDraft}
          variant="contained"
          sx={{ mt: 2, mr: 1 }}
          disabled={!canSubmit || mutationLoading}
        >
          {t("save and complete later")}
        </Button>
        <Button
          type="submit"
          variant="contained"
          sx={{ mt: 2 }}
          disabled={!canSubmit || mutationLoading || assignLoading}
        >
          {t("save and send to the project manager")}
        </Button>
      </Form>
    </>
  );
};
