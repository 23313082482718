import { gql } from "@apollo/client";

export const ASSIGN_TASK_TO_NEGOTIATOR = gql`
  mutation AssignTaskToNegotiator(
    $negotiatorId: Int!
    $taskInput: AssignTaskToNegotiatorInput!
  ) {
    assignTaskToNegotiator(negotiatorId: $negotiatorId, taskInput: $taskInput) {
      tasks {
        id
      }
    }
  }
`;
