import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TaskFormBeneficiaryData, TaskFormClientPreferences } from "./TaskForm";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_SIGNLE_TASK } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { NavLink } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { isProjectManager } from "@/shared/utils/mappers";

export const Task = () => {
  const { t } = useTranslation();
  const [formKey, setFormKey] = useState("Receiving_beneficiary_data");
  const { taskId, orderId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const { data: taskRes, loading } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
  });
  if (loading) return <Loading />;
  const task = taskRes?.tasks?.data?.at(0);
  const disableClientPreferencesForm =
    !task?.formData?.beneficiaryData ||
    task?.formData?.beneficiaryData?.contact?.callStatus === "لم يتم الرد";

  return (
    <div>
      <Box display="flex" alignItems="center" mb={2}>
        <NavLink
          to={isProjectManager(userInfo) ? `../orders/${orderId}` : "../tasks"}
        >
          <EastIcon fontSize="small" sx={{ mr: 1 }} />
        </NavLink>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("task")} {taskId}#
        </Typography>
      </Box>

      {task?.status?.key === 3 ? (
        <>
          <Typography
            color="error"
            fontWeight="bold"
            textAlign="center"
            sx={{ mb: 1 }}
          >
            هذه المهمة مرفوضة من المستفيد
          </Typography>

          <Box display="flex" justifyContent="center">
            <Typography fontWeight="bold" sx={{ mb: 2 }} color="text.secondary">
              سبب الرفض: &nbsp;
            </Typography>
            <Typography textAlign="center" fontWeight="bold">
              {taskRes?.tasks?.data?.at(0)?.formData?.beneficiaryData?.contact
                ?.reason === "أخرى"
                ? taskRes?.tasks?.data?.at(0)?.formData?.beneficiaryData
                    ?.contact?.comment
                : taskRes?.tasks?.data?.at(0)?.formData?.beneficiaryData
                    ?.contact?.reason}
            </Typography>
          </Box>
        </>
      ) : (
        <>
          <Tabs
            value={formKey}
            sx={{ position: "sticky", top: 100 }}
            onChange={(event: React.SyntheticEvent, newValue: string) => {
              setFormKey(newValue);
            }}
          >
            <Tab
              sx={{ fontWeight: "bold" }}
              label={t("Recieving Orders")}
              value="Receiving_beneficiary_data"
            />
            <Tab
              sx={{ fontWeight: "bold" }}
              label={t("Client Preferences")}
              value="client_preferences"
              disabled={disableClientPreferencesForm}
            />
          </Tabs>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}></Grid>
            <Grid item xs={12} md={6}>
              {formKey === "Receiving_beneficiary_data" ? (
                <TaskFormBeneficiaryData
                  setFormKey={setFormKey}
                  formKey={formKey}
                />
              ) : (
                <TaskFormClientPreferences formKey={formKey} />
              )}
            </Grid>
            <Grid item xs={12} md={3}></Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
