import { FC } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSorting } from "@/shared/hooks";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { EmptyData } from "@/components/EmptyData";

export const TasksList: FC<{ tasks: any[] }> = ({ tasks }) => {
  const { t } = useTranslation();
  const { orderBy, sortingValue } = useSorting();

  if (tasks?.length === 0) return <EmptyData />;
  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Task Number")}</TableCell>
              <TableCell>{t("External ID")}</TableCell>
              <TableCell>{t("Name")}</TableCell>
              <TableCell>{t("Phone number")}</TableCell>
              <TableCell>{t("Region")}</TableCell>
              <TableCell>{t("City")}</TableCell>
              <TableCell>{t("Product")}</TableCell>
              <TableCell>
                <Button
                  sx={{ color: "inherit", fontWeight: "inherit" }}
                  onClick={() => orderBy("order.start_date")}
                >
                  {t("Start date")}
                  {sortingValue === "-order.start_date" ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  sx={{ color: "inherit", fontWeight: "inherit" }}
                  onClick={() => orderBy("order.end_date")}
                >
                  {t("End date")}
                  {sortingValue === "-order.end_date" ? (
                    <SouthIcon fontSize="small" />
                  ) : (
                    <NorthIcon fontSize="small" />
                  )}
                </Button>
              </TableCell>
              <TableCell>{t("rescheduled contact")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks?.map((task) => {
              const name = task?.formData?.beneficiaryData?.personalData?.name;
              const region = task?.formData?.beneficiaryData?.location?.region;
              const city = task?.formData?.beneficiaryData?.location?.city;
              const product =
                task?.formData?.beneficiaryData?.supportPackages?.product;
              return (
                <TableRow component={NavLink} to={task.id}>
                  <TableCell>{task.id}</TableCell>
                  <TableCell>{task.beneficiary?.externalId}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell>
                    {
                      task?.formData?.beneficiaryData?.personalData
                        ?.mobileNumber
                    }
                  </TableCell>
                  <TableCell>{region}</TableCell>
                  <TableCell>{city}</TableCell>
                  <TableCell>{product}</TableCell>
                  <TableCell>
                    {new Date(task.order.startDate).toLocaleDateString("en-GB")}
                  </TableCell>
                  <TableCell>
                    {new Date(task.order.endDate).toLocaleDateString("en-GB")}
                  </TableCell>
                  <TableCell>
                    <div>
                      {
                        task?.formData?.beneficiaryData?.contact?.expectedCall
                          ?.expectedCallDate
                      }
                    </div>
                    <div>
                      {
                        task?.formData?.beneficiaryData?.contact?.expectedCall
                          ?.expectedCallTime
                      }
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
