import { gql } from "@apollo/client";

export const GET_ALL_FAVOURITE_OFFERS = gql`
  query favoriteOffers($taskId: Int!) {
    favoriteOffers(taskId: $taskId) {
      data {
        id
        licenseNumber
        offerType
        data
        status
      }
      count
    }
  }
`;
