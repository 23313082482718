import { Loading } from "@/components/Loading";
import {
  DatePickerWidget,
  FileWidget,
  TextWidget,
  YesNoWidget,
} from "@/components/RJSF/widgets";
import { GET_SCHEMA_BY_KEY, GET_SIGNLE_TASK } from "@/shared/graphql";
import { useMutation, useQuery } from "@apollo/client";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { CustomObjectFieldTemplate as ObjectFieldTemplate } from "@/components/RJSF/templates/CustomObjectFieldTemplate";
import { Button } from "@mui/material";
import { REJECT_TASK, UPDATE_TASK } from "@/shared/graphql/mutations";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import {
  fetchAllCitiesByRegionId,
  fetchAllRegions,
} from "@/shared/graphql/services/layers";
import { graphqlErrorHandler } from "@/shared/utils";
import { isCustomerService } from "@/shared/utils/mappers";
// import schema1 from "@/schemas/1-request.json";
// import uiSchema1 from "@/schemas/ui-schemas/1-request.json";

export const TaskFormBeneficiaryData = ({ formKey, setFormKey }) => {
  const [formData, setFormData] = useState<any>({
    contact: {
      expectedCall: {},
    },
    request: {},
    location: {},
    familyData: {},
    personalData: {},
    financialData: {},
    supportPackages: {},
    professionalData: {},
    basicTransactionData: {},
    personalRealEstateData: {},
  });
  const { taskId } = useParams();
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const [formSchema, setFormSchema] = useState({});
  const [funders, setFunders] = useState({});
  const [regions, setRegions] = useState({});
  const { t } = useTranslation();
  const navigate = useNavigate();

  const canSubmit = userInfo?.isSuperuser || isCustomerService(userInfo);
  const willReject =
    formData?.contact?.willingnessToContinueProgram ===
    "لا يرغب في استكمال البرنامج";

  const { loading: taskLoading, data: taskRes } = useQuery(GET_SIGNLE_TASK, {
    variables: { pk: taskId, role: userInfo?.role?.queryVar },
    onCompleted: (res) => {
      if (res?.tasks?.data?.at(0)?.formData?.drafts?.beneficiaryData) {
        setFormData((prev) => ({
          ...prev,
          ...res?.tasks?.data?.at(0)?.formData?.drafts?.beneficiaryData,
        }));
      } else if (res?.tasks?.data?.at(0)?.formData?.beneficiaryData) {
        const clonedBeneficiaryData = JSON.parse(
          JSON.stringify(res?.tasks?.data?.at(0)?.formData?.beneficiaryData)
        );
        setFormData((prev) => ({
          ...prev,
          ...clonedBeneficiaryData,
        }));
      }
    },
  });

  const { data, loading: schemaLoading } = useQuery(GET_SCHEMA_BY_KEY, {
    variables: { key: formKey },
    skip: !taskRes,
    onCompleted: async (res) => {
      const clonedFormData = JSON.parse(
        JSON.stringify(taskRes?.tasks?.data?.at(0)?.formData)
      );
      const requestedFromSchema = JSON.parse(
        JSON.stringify(res?.schemas?.data?.at(0)?.jsonSchema?.form)
      );
      // fetching regions
      const regionsRes = await fetchAllRegions();
      const allRegions = regionsRes?.data?.regions?.data;
      const regionsMap = regionsRes?.data?.regions?.data?.reduce(
        (hashmap, item) => {
          hashmap[item?.sourceProperties.region_name] =
            item?.sourceProperties?.id;
          return hashmap;
        },
        {}
      );
      if (requestedFromSchema?.definitions?.location?.properties?.region) {
        requestedFromSchema.definitions.location.properties.region.enum =
          allRegions?.map((region) => region.sourceProperties.region_name);
      }
      setRegions(regionsMap);

      // checking if the region default value exists
      const prefilledRegion =
        clonedFormData?.drafts?.beneficiaryData?.location?.region ||
        clonedFormData?.beneficiaryData?.location?.region;
      if (regionsMap[prefilledRegion]) {
        // fetchCities by region id
        const citiesRes = await fetchAllCitiesByRegionId(
          regionsMap[prefilledRegion]?.toString()
        );

        const allCities = citiesRes?.data?.cities?.data;
        if (requestedFromSchema?.definitions?.location?.properties?.city) {
          requestedFromSchema.definitions.location.properties.city.enum =
            allCities?.map((city) => city.sourceProperties.city_name);
        }
      }

      // inserting funders in form schema
      const beneficiaryFinancialData =
        clonedFormData?.drafts?.beneficiaryData?.financialData ||
        clonedFormData?.beneficiaryData?.financialData;
      const tempMap = {};
      const fundersMap = {};

      Object.entries(beneficiaryFinancialData)?.forEach(([key, value]) => {
        if (key === "funderAmount" || key === "funderBank") return;
        if (key.includes("funder")) {
          tempMap[key] = {
            name: value,
          };
        }
      });

      Object.entries(beneficiaryFinancialData)?.forEach(([key, value]) => {
        if (key.includes("AIP")) {
          const aipIndex = key.at(-1);
          tempMap[`funder${aipIndex}`].amount = value;
        }
      });

      // deleting null funders
      for (const key in tempMap) {
        if (!tempMap[key].name || !tempMap[key].amount) {
          delete tempMap[key];
        }
      }

      const fundersNames: string[] = [];
      Object.entries(tempMap).forEach(([, funder]: any[]) => {
        fundersMap[funder.name] = funder.amount;
        fundersNames.push(funder.name);
      });
      setFunders(fundersMap);
      if (
        requestedFromSchema?.definitions?.financialData?.properties?.funderBank
      ) {
        requestedFromSchema.definitions.financialData.properties.funderBank.enum =
          fundersNames;
      }
      setFormSchema(requestedFromSchema);
    },
  });

  const [updateTask, { loading: updateTaskLoading }] = useMutation(UPDATE_TASK);
  const [rejectTaskMutate, { loading: isRejectLoading }] =
    useMutation(REJECT_TASK);

  const onFormChange = async (form: any, fieldId) => {
    const clonedFormData = JSON.parse(JSON.stringify(form?.formData));
    if (fieldId === "root_financialData_funderBank") {
      // insert funder amount in form data
      if (clonedFormData?.financialData?.funderBank) {
        clonedFormData.financialData.funderAmount =
          funders[clonedFormData?.financialData?.funderBank];
      }
    }
    if (fieldId === "root_location_region") {
      const citiesRes = await fetchAllCitiesByRegionId(
        regions[form?.formData?.location?.region]?.toString()
      );

      const clonedFormSchema = JSON.parse(JSON.stringify(formSchema));
      const allCities = citiesRes?.data?.cities?.data;
      if (clonedFormSchema?.definitions?.location?.properties?.city) {
        clonedFormSchema.definitions.location.properties.city.enum =
          allCities?.map((city) => city.sourceProperties.city_name);
      }
      setFormSchema(clonedFormSchema);
    }
    setFormData((prev) => ({ ...prev, ...clonedFormData }));
  };

  const onFileChange = async (data) => {
    return new Promise((resolve) => {
      if (Array.isArray(data)) {
        const result: any = [];
        data.forEach(({ file }) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            result.push(reader.result);
            if (data.length === result.length) {
              resolve(result);
            }
          };
        });
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(data.file);
        reader.onload = () => {
          resolve(reader.result);
        };
      }
    });
  };

  const saveAsDraft = () => {
    const submittedData = { beneficiaryData: formData };
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: true,
        },
      },
    })
      .then(() => {
        navigate("../tasks");
        toast.success(t("data has been drafted"));
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const onSubmit = (form) => {
    const submittedData = { beneficiaryData: form.formData };

    const isRejected =
      submittedData?.beneficiaryData?.contact?.willingnessToContinueProgram ===
      "لا يرغب في استكمال البرنامج";

    const willRedirectToTasks =
      isRejected ||
      submittedData?.beneficiaryData?.contact?.willingnessToContinueProgram ===
        "إعادة جدولة الإتصال" ||
      submittedData?.beneficiaryData?.contact?.callStatus === "لم يتم الرد";
      
    if (isRejected) {
      rejectTaskMutate({
        variables: {
          taskInput: {
            taskId,
            formSchemaKey: formKey,
            formData: JSON.stringify(submittedData),
          },
        },
      })
        .then(() => {
          navigate("../tasks");
          toast.success(t("Task Rejected Successfully"));
        })
        .catch((err) => {
          graphqlErrorHandler(err);
        });
      return;
    }
    updateTask({
      variables: {
        taskInput: {
          taskId,
          formSchemaKey: formKey,
          formData: JSON.stringify(submittedData),
          isDraft: false,
        },
      },
    })
      .then(() => {
        toast.success(t("Data has been saved successfully"));
        if (willRedirectToTasks) {
          navigate("../tasks");
          return;
        }
        setFormKey("client_preferences");
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  const schema = data?.schemas?.data?.at(0)?.jsonSchema;
  const uiSchema = schema?.UISchema;
  if (schemaLoading || taskLoading || !formSchema) return <Loading />;
  const mutationLoading = updateTaskLoading || isRejectLoading;
  return (
    <Form
      formContext={{ onFileChange }}
      schema={formSchema || {}}
      uiSchema={uiSchema}
      validator={validator}
      showErrorList={false}
      onChange={onFormChange}
      formData={formData}
      experimental_defaultFormStateBehavior={{
        allOf: "populateDefaults",
      }}
      noHtml5Validate
      transformErrors={(errors) => {
        const modfiedErrors = errors?.map((err) => {
          if (
            err.name === "required" ||
            err.name === "minItems" ||
            err.name === "type"
          ) {
            return { ...err, message: "حقل مطلوب" };
          }
          if (err.name === "enum") {
            return {
              ...err,
              message: "يرجى الإختيار من القيم الموجودة",
            };
          }
          if (err.name === "if") {
            return {
              ...err,
              message: "",
            };
          }
          return err;
        });
        return modfiedErrors;
      }}
      widgets={{
        FileWidget,
        YesNoWidget,
        TextWidget,
        DateWidget: DatePickerWidget,
      }}
      templates={{ ObjectFieldTemplate }}
      onSubmit={onSubmit}
    >
      <Button
        onClick={saveAsDraft}
        variant="contained"
        sx={{ mt: 2, mr: 1 }}
        disabled={!canSubmit || mutationLoading || willReject}
      >
        {t("save and complete later")}
      </Button>
      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={!canSubmit || mutationLoading}
      >
        {t("save")}
      </Button>
    </Form>
  );
};
