import { FC, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Divider,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import { useQuery } from "@apollo/client";
import { GET_TASKS } from "@/shared/graphql";
import { Loading } from "@/components/Loading";
import { EmptyData } from "@/components/EmptyData";
import { useSelector } from "react-redux";
import { RootState } from "@/shared/store";
import { StatusChip } from "@/components/StatusChip";
import { OrderTasksActions } from "./OrderTasksActions";
import { AssignToNegotiatorModal } from "./AssignToNegotiatorModal";

export const OrderTasks: FC = () => {
  const { orderId } = useParams();
  const { t } = useTranslation();
  const [selectedTasks, setSelectedTasks] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams({ status: "" });
  const pageLimit = 10;
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const status = searchParams.get("status") || "";
  const filters = [{ field: "order_id", value: orderId }];
  if (status) {
    filters.push({ field: "status", value: status });
  }
  const {
    data,
    loading,
    refetch: refetchTasks,
  } = useQuery(GET_TASKS, {
    variables: {
      role: userInfo?.role?.queryVar,
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      orderBy: "-modified",
      filters,
    },
  });

  const tasks = data?.tasks?.data;

  const numberOfPages = Math.ceil(data?.tasks?.count / pageLimit);
  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  const handleCheckboxChange = (isChecked: boolean, taskId: number) => {
    if (!isChecked) {
      setSelectedTasks((prev: number[]) => {
        return [...prev, taskId];
      });
    } else {
      setSelectedTasks((prev: number[]) => {
        const updated = prev.filter((el) => el !== taskId);
        return updated;
      });
    }
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedTasks((prev) => {
        const updated = [...prev];
        tasks.forEach((ben) => {
          if (!updated.includes(ben.id)) {
            updated.push(ben.id);
          }
        });

        return updated;
      });
    } else {
      setSelectedTasks((prev) => {
        let updated = [...prev];
        tasks.forEach((ben) => {
          updated = updated.filter((id) => ben.id !== id);
        });
        return updated;
      });
    }
  };

  let isAllChecked = true;
  tasks?.forEach((ben) => {
    if (selectedTasks.includes(ben.id)) {
      isAllChecked = isAllChecked && true;
    } else {
      isAllChecked = isAllChecked && false;
    }
  });

  if (loading) return <Loading />;
  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <NavLink to="../orders">
          <EastIcon fontSize="small" sx={{ mr: 1 }} />
        </NavLink>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {t("Order")} {orderId}#
        </Typography>
      </Box>
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        {t("Tasks")}
      </Typography>

      <Card variant="outlined" sx={{ mb: 2 }}>
        <>
          {selectedTasks.length > 0 && (
            <Box display="flex" alignItems="center" px={2.4} py={1.2}>
              <Typography sx={{ mr: 1 }}>
                {t("selected")} {selectedTasks.length}
              </Typography>
              <div>
                <AssignToNegotiatorModal
                  setSelectedTasks={setSelectedTasks}
                  buttonVariant="contained"
                  taskId={selectedTasks}
                  refetch={refetchTasks}
                />
              </div>
            </Box>
          )}
          <Divider />

          <Box sx={{ px: 1 }}>
            <Tabs
              value={status}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#328AF1",
                },
              }}
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  color: "#328AF1",
                },
              }}
              onChange={(event: React.SyntheticEvent, newValue: string) => {
                setSelectedTasks([]);
                setSearchParams((params) => {
                  params.set("page", "1");
                  params.set("status", newValue);
                  return params;
                });
              }}
            >
              <Tab sx={{ fontWeight: "bold" }} label={t("all")} value="" />
              <Tab
                sx={{ fontWeight: "bold" }}
                label={t("Pending Review")}
                value="4"
              />
            </Tabs>
          </Box>
          {tasks.length === 0 ? (
            <EmptyData />
          ) : (
            <TableContainer>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {status === "4" && (
                      <TableCell>
                        <Checkbox
                          sx={{ padding: 0 }}
                          checked={isAllChecked}
                          onChange={handleSelectAll}
                          size="small"
                        />
                      </TableCell>
                    )}
                    <TableCell>{t("Task Number")}</TableCell>
                    <TableCell>{t("External ID")}</TableCell>
                    <TableCell>{t("Benificiary name")}</TableCell>
                    <TableCell>{t("Order Entity")}</TableCell>
                    <TableCell>{t("Assigned to")}</TableCell>
                    <TableCell>{t("Employee name")}</TableCell>
                    <TableCell>{t("Status")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.tasks?.data?.map((task) => {
                    const assignedTo = task?.assignedStatus;
                    const isChecked = selectedTasks.includes(task.id);

                    let navigateTo = "";
                    if (
                      assignedTo.key === 2 ||
                      assignedTo.key === 5 ||
                      (assignedTo.key === 1 && task?.status?.key === 3)
                    ) {
                      navigateTo = `customer-task/${task.id}`;
                    } else if (
                      assignedTo.key === 3 ||
                      (assignedTo.key === 1 && task?.status?.key === 5)
                    ) {
                      navigateTo = `negotiator-task/${task.id}`;
                    }

                    return (
                      <TableRow>
                        {status === "4" && (
                          <TableCell>
                            <Checkbox
                              onChange={() =>
                                handleCheckboxChange(isChecked, task.id)
                              }
                              sx={{ padding: 0 }}
                              checked={isChecked}
                              size="small"
                            />
                          </TableCell>
                        )}
                        <TableCell>
                          <Box
                            component={NavLink}
                            to={navigateTo}
                            sx={{
                              color: "rgb(0, 107, 214)",
                            }}
                          >
                            {task?.id}
                          </Box>
                        </TableCell>
                        <TableCell>{task?.beneficiary?.externalId}</TableCell>
                        <TableCell>
                          {task?.formData?.beneficiaryData?.personalData?.name}
                        </TableCell>
                        <TableCell>
                          {task?.formData?.beneficiaryData?.destination}
                        </TableCell>
                        <TableCell>
                          {t(task?.assignedStatus?.display)}
                        </TableCell>
                        <TableCell>
                          {task?.negotiator
                            ? `${task?.negotiator?.firstName} ${task?.negotiator?.lastName}`
                            : `${task?.customerService?.firstName} ${task?.customerService?.lastName}`}
                        </TableCell>
                        <TableCell>
                          <StatusChip status={task.status} />
                        </TableCell>
                        <TableCell>
                          {task?.status?.key !== 5 && (
                            <OrderTasksActions
                              assignedStatus={task.assignedStatus}
                              setSelectedTasks={setSelectedTasks}
                              status={task?.status}
                              taskId={task?.id}
                              refetch={refetchTasks}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </>
      </Card>
      <Pagination
        count={numberOfPages || 1}
        page={Number(searchParams.get("page") || 1)}
        onChange={handlePagination}
        variant="outlined"
        shape="rounded"
      />
    </>
  );
};
