import { Box, Button, Card, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import validator from "@rjsf/validator-ajv8";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { useState } from "react";
import { TextWidget } from "@/components/RJSF/widgets";
import { Form } from "@rjsf/mui";
import { useMutation } from "@apollo/client";
import { CREATE_DEAL, CREATE_EXTERNAL_OFFER } from "@/shared/graphql/mutations";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import { graphqlErrorHandler } from "@/shared/utils";
import { RootState } from "@/shared/store";
import { useSelector } from "react-redux";
import { isNegotiator } from "@/shared/utils/mappers";

export const ExternalOffer = ({ setSelectedStep, refetchTask }) => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const [open, setOpen] = useState(false);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const canSubmit =
    userInfo?.isSuperuser || isNegotiator(userInfo) || userInfo?.isSuperuser;
  const [createExternalOffer, { loading: createExOfferLoading }] = useMutation(
    CREATE_EXTERNAL_OFFER
  );

  const [createDeal, { loading: dealDealLoading }] = useMutation(CREATE_DEAL);

  const onSubmit = (form) => {
    createExternalOffer({
      variables: {
        offerInput: {
          taskId,
          url: form.formData.url,
          licenceNumber: form.formData.licenceNumber,
        },
      },
    })
      .then((res) => {
        const favoriteOfferId =
          res?.data?.createExternalOfferMutation?.favoriteOffer?.id;
        createDeal({
          variables: {
            dealInput: { taskId, favoriteOfferId },
          },
        })
          .then(() => {
            refetchTask();
            toast.success(t("Deal created successfully"));
            setSelectedStep((prev) => prev + 1);
          })
          .catch((err) => {
            graphqlErrorHandler(err);
          });
      })
      .catch((err) => {
        graphqlErrorHandler(err);
      });
  };

  return (
    <Box sx={{ maxWidth: 500 }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={1}
        sx={{ width: "100%" }}
      >
        {open && (
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            {t("External offer")}
          </Typography>
        )}
        <Button
          onClick={() => setOpen((prev) => !prev)}
          sx={{ color: "#328AF1", fontWeight: "bold" }}
        >
          {open ? <RemoveRoundedIcon /> : <AddRoundedIcon />}{" "}
          {t("Add External Offer")}
        </Button>
      </Box>
      {open && (
        <Card variant="outlined" sx={{ p: 3 }}>
          <Form
            noHtml5Validate
            showErrorList={false}
            schema={
              {
                type: "object",
                properties: {
                  url: {
                    type: "string",
                    title: "رابط العرض الخارجي",
                  },
                  licenceNumber: {
                    type: "string",
                    title: "رقم الترخيص",
                  },
                },
                required: ["url"],
              } || {}
            }
            validator={validator}
            widgets={{
              TextWidget,
            }}
            onSubmit={onSubmit}
          >
            <Button
              disabled={!canSubmit || createExOfferLoading || dealDealLoading}
              type="submit"
              variant="contained"
              sx={{ mt: 2, py: 1, fontWeight: "bold" }}
              fullWidth
            >
              {t("Create deal")}
            </Button>
            <Button
              disabled={!canSubmit || createExOfferLoading || dealDealLoading}
              variant="text"
              fullWidth
              sx={{ mt: 2, mr: 1, fontWeight: "bold" }}
              color="error"
              onClick={() => setOpen(false)}
            >
              {t("Cancel")}
            </Button>
          </Form>
        </Card>
      )}
    </Box>
  );
};
