// import { Grid } from "@mui/material";
// import { OfferDetails } from "./OfferDetails";
// import { OffersTable } from "./OffersTable";
// import { OffersMap } from "./OffersMap";

import { EmptyData } from "@/components/EmptyData";

export const Offers = () => {
  return (
    <div>
      <EmptyData />
      {/* <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <OfferDetails />
          <OffersTable />
        </Grid>
        <Grid item xs={12} lg={4}>
          <OffersMap />
        </Grid>
      </Grid> */}
    </div>
  );
};
