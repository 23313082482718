/* eslint-disable prefer-rest-params */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export function initClarity() {
  const isProd = import.meta.env.VITE_APP_DEPLOYMENT === "PRODUCTION";
  if (isProd) {
    // init clarity for production
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(
      window,
      document,
      "clarity",
      "script",
      import.meta.env.VITE_CLARITY_SCRIPT_ID
    );
    return;
  }
}
