import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FilterSelect, FilterTag } from "@/components/Filters";
import {} from "@/_mocks/filterOptions";
import { useQuery } from "@apollo/client";
import { GET_BENEFICIARIES_FILTER_OPTIONS } from "@/shared/graphql";
import { useTasksFilter } from "./useTasksFilter";

export const TasksFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const { data } = useQuery(GET_BENEFICIARIES_FILTER_OPTIONS);
  const { t } = useTranslation();
  const { isFilterKeysExist } = useTasksFilter();
  const renderedTags: React.ReactNode[] = [];
  searchParams.forEach((value, key) => {
    if (key !== "page" && key !== "name") {
      let displayedvalue = value;
      let displayedKey = key;
      if (key === "orderBy") {
        if (value?.at(0) === "-") {
          displayedvalue = value.slice(1);
          displayedKey = "descending order";
        } else {
          displayedKey = "ascending order";
        }
        displayedvalue = t(displayedvalue);
      }

      renderedTags.push(
        <Box
          key={`${displayedKey} ${displayedvalue}`}
          sx={{ display: "inline-flex", mr: 1, mt: 1 }}
        >
          <FilterTag
            field={key}
            text={`${t(`filterFields.${displayedKey}`)}: `}
            displayedvalue={displayedvalue}
            value={value}
          />
        </Box>
      );
    }
  });

  const regions = data?.beneficiaryFilterOptions?.region.map((el: string) => ({
    label: el,
    value: el,
  }));

  const cities = data?.beneficiaryFilterOptions?.city.map((el: string) => ({
    label: el,
    value: el,
  }));

  const products = data?.beneficiaryFilterOptions?.product.map(
    (el: string) => ({
      label: el,
      value: el,
    })
  );

  // const jobs = data?.beneficiaryFilterOptions?.job.map((el: string) => ({
  //   label: el,
  //   value: el,
  // }));

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={1} sx={{ mb: 1 }}>
        <Grid item xs={12} sm={6} md={2.4}>
          <OutlinedInput
            fullWidth
            placeholder={t("Search by name")}
            value={searchParams.get("name") || ""}
            startAdornment={
              <InputAdornment position="end" sx={{ pr: 1 }}>
                <SearchIcon />
              </InputAdornment>
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchParams((params) => {
                params.delete("page");
                if (e.target.value) {
                  params.set("name", e.target.value);
                } else {
                  params.delete("name");
                }

                return params;
              });
            }}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <FilterSelect
            isMulti
            options={regions}
            name="region"
            placeholder={t("Region")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <FilterSelect
            isMulti
            options={cities}
            name="city"
            placeholder={t("City")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <FilterSelect
            isMulti
            options={products}
            name="product"
            placeholder={t("Product")}
          />
        </Grid>
      </Grid>
      <Box display="flex" flexWrap="wrap" alignItems="cneter">
        {renderedTags}
        {isFilterKeysExist && (
          <Button
            sx={{ mr: 1, mt: 1, p: 0 }}
            onClick={() => setSearchParams({})}
          >
            <FilterTag
              showCloseButton={false}
              field={""}
              text=""
              value=""
              color="error"
              displayedvalue={t("delete-all")}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};
